<template>
	<div class="wrapper">
		<!-- uni 中不能使用 vue component 所以用if判断每个组件 -->
		<div v-for="(item, index) in pageData.list" :key="index">
			<carousel v-if="item.type == 'carousel'" :res="item.options" />
			<leftOneRightTwo v-if="item.type == 'leftOneRightTwo'" :res="item.options" />
			<leftTwoRightOne v-if="item.type == 'leftTwoRightOne'" :res="item.options" />
			<topOneBottomTwo v-if="item.type == 'topOneBottomTwo'" :res="item.options" />
			<topTwoBottomOne v-if="item.type == 'topTwoBottomOne'" :res="item.options" />
			<flexThree v-if="item.type == 'flexThree'" :res="item.options" />
			<flexFive v-if="item.type == 'flexFive'" :res="item.options" />
			<flexFour v-if="item.type == 'flexFour'" :res="item.options" />
			<flexTwo v-if="item.type == 'flexTwo'" :res="item.options" />
			<textPicture v-if="item.type == 'textPicture'" :res="item.options" />
			<flexOne @callback="handelPopo" v-if="item.type == 'flexOne'" :res="item.options" />
			<span v-if="item.type == 'tabbar'" style="padding: 120rpx 2rpx"></span>
			<!-- 收集用户信息，包括手机号，姓名，家庭地址，住房面积等 -->
			<tplCollect v-if="item.type == 'collect'" @callback="subscribeCustomerInfo" :res="item.options"
				:pageDescription="getCustomerData.pageDescription" />
		
			<!-- 下面是弹窗 -->
			<transition name="el-fade-in">
				<div class="prop" v-if="flag">
					<div class="close" @click="close()">
						<img src="http://mjgw.oss-cn-shanghai.aliyuncs.com/upload/2022-08-31/c52e0f1e666647e391488b12cb48c66a.png"
							alt="" />
					</div>
					<div :style="[
		            {
		              backgroundImage: 'url(' + propUrl + ')',
		            },
		          ]" class="prop-top"></div>
					<div class="prop-bottom">
						<div class="ipt1">
							<span class="txt1 f14">您的称呼：</span>
							<input class="file f14" type="text" v-model="getCustomerData.name" maxlength="11" />
						</div>
						<div class="ipt2">
							<span class="txt1 f14">手机号码：</span>
							<input class="file f14" type="text" maxlength="11" v-model="getCustomerData.mobile" />
						</div>
						<div @click="submitInfo" :style="propBtnStyle" class="txt">
							{{ propBtn.name }}
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { getPageData } from "@/api/portal/page-data.js";
import { postmobile } from '@/api/views/name.js'
	// 引用组件
	import tpl_left_one_right_two from "@/views/active-mob/template/tpl-left-one-right-two"; //左一右二模块
	import tpl_left_two_right_one from "@/views/active-mob/template/tpl-left-two-right-one"; //左二右一模块
	import tpl_top_one_bottom_two from "@/views/active-mob/template/tpl-top-one-bottom-two"; //上一下二模块
	import tpl_top_two_bottom_one from "@/views/active-mob/template/tpl-top-two-bottom-one"; //上二下一模块
	import tpl_flex_one from "@/views/active-mob/template/tpl-flex-one"; //单行图片模块
	import tpl_flex_two from "@/views/active-mob/template/tpl-flex-two"; //两张横图模块
	import tpl_flex_three from "@/views/active-mob/template/tpl-flex-three"; //三列单行图片模块
	import tpl_flex_five from "@/views/active-mob/template/tpl-flex-five"; //五列单行图片模块
	import tpl_flex_four from "@/views/active-mob/template/tpl-flex-four"; //四列单行图片模块
	import tpl_text_picture from "@/views/active-mob/template/tpl-text-picture"; //文字图片模板
	import tplCollect from "@/views/active-mob/template/tpl-collect"; //文字图片模板

	// 结束引用组件
	//import { getFloorData } from "@/api/home"; //获取楼层装修接口


	// import {
	// 	getPlatform
	// } from "@/utils/util.js";

	export default {
		props: {
			pageNo: {
				type: String,
				default: "",
			},
			//渠道-营销活动-广告计划-广告单元
			adNo: {
				type: String,
				default: "",
			},
		},

		data() {
			return {
				flag3: false,
				flag: false,
				pageData: "", //楼层页面数据
				isIos: "",
				propUrl: '',
				getCustomerData: {
					bizSource: "H5", //业务来源，（H5，PC，微信小程，支付宝小程，百度小程序等）
					mediaName: undefined, // 媒体名称
					mediaCode: undefined, //媒体来源代码
					channelCode: undefined, //渠道代码
					activityCode: undefined, //营销活动代码
					projectCode: undefined, //广告计划
					projectName: undefined, // 广告计划名称
					adUnitCode: undefined, //广告单元代码
					name: undefined, //姓名
					mobile: undefined, //手机号
					city: undefined, //城市代码
					cityCode: undefined, //城市代码
					salesRoom: undefined, //门店名称
					salesRoomNo: undefined, //门店代码
					visitorId: undefined, //
					operatingSystem: undefined,
					mobileModel: undefined,
					browserType: undefined,
					reqUrl: undefined,
					pageDescription: undefined, // 页面介绍
				},
				rules: {
					name: {
						required: true,
						message: "请输入姓名",
						// 可以单个或者同时写两个触发验证方式
						trigger: ["blur"],
					},
					// 手机号
					mobile: [{
							required: true,
							message: "请输入手机号",
							trigger: ["change", "blur"],
						},
						{
							// 自定义验证函数，见上说明
							validator: (rule, value, callback) => {
								return this.$u.test.mobile(value);
							},
							message: "手机号码不正确",
							// 触发器可以同时用blur和change
							trigger: ["change", "blur"],
						},
					],
				},
			};
		},
		computed: {
			propBtnStyle() {
				return {
					backgroundImage: "url(" + this.propBtn.propBtnUrl + ")",
					width: this.propBtn.bg_width + "px",
					height: this.propBtn.bg_height + "px",
					color: this.propBtn.color,
					fontSize: this.propBtn.font_size + "px",
					borderRadius: this.propBtn.border_radius + "px",
				};
			},
		},
		components: {
			leftOneRightTwo: tpl_left_one_right_two,
			leftTwoRightOne: tpl_left_two_right_one,
			topOneBottomTwo: tpl_top_one_bottom_two,
			topTwoBottomOne: tpl_top_two_bottom_one,
			flexThree: tpl_flex_three,
			flexFive: tpl_flex_five,
			flexFour: tpl_flex_four,
			flexTwo: tpl_flex_two,
			textPicture: tpl_text_picture,
			flexOne: tpl_flex_one,
            tplCollect
		},

		mounted() {
			this.init();
		},

		methods: {
			handelPopo(val) {
				this.flag = val;
			},
			close() {
				this.flag = false;
			},
			/**
			 * 实例化首页数据楼层
			 */
			init() {
				this.pageData = "";
				getPageData(this.pageNo).then((res) => {
					if (res.code == 0) {
                        document.title = res.data.title;
						this.getCustomerData.pageDescription = res.data.description;
						this.pageData = JSON.parse(res.data.pageData);
						this.pageData.list.forEach((element) => {
							for (let key in element) {
								// if (element[key] == "topAd") {
								// 	// console.log(element.options.list[0].bg_color);
								// 	this.bg_color = element.options.list[0].bg_color;
								// }
								if (element[key] == "flexOne") {
									this.propUrl = element.options.list[0].propStyle.propUrl;
									this.propBtn = element.options.list[0].propStyle;
								}
							}
						});
                        console.log("this.pageData ",this.pageData );


						// this.pageData.list.forEach((obj) => {
						// 	if (obj.model == 'stylist') {
						// 		this.flag3 = true
						// 	}
						// })
					}
				});
				//转换代码
				if (this.adNo) {
					//渠道-媒体-营销活动-广告计划-广告单元
					var adNos = this.adNo.split("-");
					this.getCustomerData.channelCode = adNos[0]; //渠道代码
					this.getCustomerData.mediaCode = adNos[1]; //媒体代码
					this.getCustomerData.activityCode = adNos[2]; //营销活动代码
					this.getCustomerData.projectCode = adNos[3]; //广告计划
					this.getCustomerData.adUnitCode = adNos[4]; //广告单元代码
				}
			},


			submitInfo() {
				let _that = this;
				// console.log(this.collectForm.mobile);
				if (this.getCustomerData.mobile == undefined) {
					uni.$u.toast("请输入正确的手机号");
				} else if (
					!this.getCustomerData.mobile.match(/^[1][3,4,5,6.7,8,9][0-9]{9}$/)
				) {
					uni.$u.toast("请输入正确的手机号");
					this.getCustomerData.name = "";
					this.getCustomerData.mobile = "";
				} else {
					//系统数据
					const res = uni.getSystemInfoSync();
					this.getCustomerData.visitorId = res.deviceId; //访客Id
					this.getCustomerData.operatingSystem = res.system; //操作系统
					this.getCustomerData.mobileModel = res.model; //手机类型
					this.getCustomerData.browserType = res.platform; //使用平台
					// this.getCustomerData.bizSource = getPlatform();
					//请求地址数据
					let curPage = getCurrentPages();
					let route = curPage[curPage.length - 1].route; //获取当前页面的路由
					let params = curPage[curPage.length - 1].options; //获取当前页面参数，如果有则返回参数的对象，没有参数返回空对象{}
					let query = "";
					let keys = Object.keys(params); //获取对象的key 返回对象key的数组
					if (keys.length > 0) {
						query = keys
							.reduce((pre, cur) => {
								return pre + cur + "=" + params[cur] + "&";
							}, "?")
							.slice(0, -1);
					}
					this.getCustomerData.reqUrl = "https://m.mingjugroup.com/" + route + query; ////获取当前页面的路由

					this.$http.postmobile(this.getCustomerData).then((res) => {
						if (res.code == "0") {
							uni.$u.toast("感谢您的信任，我们会尽快安排工作人员联系您。");
						} else {
							uni.$u.toast(res.msg);
						}
						this.flag = false;
					});
					this.getCustomerData.name = "";
					this.getCustomerData.mobile = "";
				}
			},

			/**
			 * 提交获客数据信息
			 * @param {Object} collectForm
			 */
             async subscribeCustomerInfo(collectForm) {
                // console.log("subscribeCustomerInfo  --->" + collectForm);
                this.getCustomerData.name = collectForm.name;
                this.getCustomerData.mobile = collectForm.mobile;
                this.getCustomerData.city = collectForm.cityCode;
                this.getCustomerData.salesRoomNo = collectForm.salesRoomNo;
                //系统数据

                this.getCustomerData.visitorId = this.$Cookie.get("MJ0INTERVIEWID"); //访客Id
                this.getCustomerData.operatingSystem =
                    this.$Cookie.set("operatingSystem"); //操作系统
                // this.getCustomerData.mobileModel = res.model; //手机类型
                this.getCustomerData.browserType = this.$Cookie.set("browserType"); //使用平台

                this.getCustomerData.reqUrl = window.location.href; ////获取当前页面的路由

                const res = await postmobile(this.getCustomerData)
                this.$message.closeAll()
                this.$message({
                    message:
                        '提交成功！稍后家装顾问与您联系，请注意021开头电话',
                    duration: 2000,
                    offset: 120,
                })

             
            },
		},
	};
</script>/*
<!-- --> */

<style scoped lang="scss">
	.navbar-right {
		padding: 0 16rpx 0 0;
	}

	.prop {
		position: fixed;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 560rpx;
		height: 600rpx;
		box-shadow: 0px 14rpx 30rpx 0px rgba(0, 0, 0, 0.3);
		border-radius: 12rpx;
		transition: opacity 0.2s ease-in;
		transition: opacity 0.2s ease-out;

		.close {
			width: 68rpx;
			height: 68rpx;
			background: #ffffff;
			opacity: 0.9;
			border-radius: 50%;
			position: absolute;
			top: -34rpx;
			right: -34rpx;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.prop-top {
			width: 560rpx;
			height: 154rpx;
			border-radius: 12rpx 12rpx 0 0;
			background-size: 100%, 100%;
		}

		.prop-bottom {
			width: 560rpx;
			height: 446rpx;
			background: #fff;
			border-radius: 0 0 12rpx 12rpx;
			padding: 50rpx 0 0 40rpx;
			box-sizing: border-box;
			position: relative;

			.ipt1 {
				width: 481rpx;
				height: 81rpx;
				background: #ffffff;
				border-radius: 10rpx;
				border: 1rpx solid #e5e5e5;
				position: absolute;
				top: 50rpx;
				left: 40rpx;

				.txt1 {
					width: 145rpx;
					height: 26rpx;
					font-weight: 400;
					color: #666666;
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 27rpx;
					left: 29rpx;
					font-size: 28rpx;
				}

				span {
					width: 340rpx;
					height: 24rpx;
					font-weight: 400;
					color: #656565;
					line-height: 24rpx;
					display: inline-block;
					position: absolute;
					top: 304rpx;
					left: 0rpx;
				}

				.file {
					width: 268rpx;
					font-weight: 400;
					color: #999797;
					line-height: 24rpx;
					font-size: 28rpx;
					outline: none;
					position: absolute;
					left: 165rpx;
					border: none;
				}
			}

			.ipt2 {
				width: 481rpx;
				height: 81rpx;
				background: #ffffff;
				border-radius: 10rpx;
				border: 1rpx solid #e5e5e5;
				position: absolute;
				top: 180rpx;
				left: 40rpx;

				.txt1 {
					width: 145rpx;
					height: 26rpx;
					font-weight: 400;
					color: #666666;
					font-size: 28rpx;
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 27rpx;
					left: 29rpx;
				}

				span {
					width: 340rpx;
					height: 24rpx;
					font-weight: 400;
					color: #656565;
					line-height: 24rpx;
					display: inline-block;
					position: absolute;
					top: 304rpx;
					left: 0rpx;
				}

				.file {
					width: 268rpx;
					font-weight: 400;
					color: #999797;
					line-height: 24rpx;
					font-size: 28rpx;
					outline: none;
					position: absolute;
					left: 165rpx;
					border: none;
				}
			}

			.txt {
				width: 480rpx;
				height: 80rpx;
				margin: 262rpx 0 0 0;
				border-radius: 10rpx;
				font-size: 36rpx;
				
				color: #ffffff;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				user-select: none;
			}
		}
	}
</style>
