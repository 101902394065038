<template>
	<div class="layout">
		<div class="flex-one" :style="bgStyle">
			<img image-menu-prevent="true" mode="widthFix" :src="res.list[0].img" alt="">
			<div @click="open()" v-if="res.list[0].btns && res.list[0].btns[0]" :style="buttonStyle0" class="btns">
				{{ res.list[0].btns[0].name }}
			</div>
			<div @click="open()" v-if="res.list[0].btns && res.list[0].btns[1]" :style="buttonStyle1" class="btns">
				{{ res.list[0].btns[1].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[2]" :style="buttonStyle2"
				class="btns">
				{{ this.res.list[0].btns[2].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[3]" :style="buttonStyle3"
				class="btns">
				{{ this.res.list[0].btns[3].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[4]" :style="buttonStyle4"
				class="btns">
				{{ this.res.list[0].btns[4].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[5]" :style="buttonStyle5"
				class="btns">
				{{ this.res.list[0].btns[5].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[6]" :style="buttonStyle6"
				class="btns">
				{{ this.res.list[0].btns[6].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[7]" :style="buttonStyle7"
				class="btns">
				{{ this.res.list[0].btns[7].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[8]" :style="buttonStyle8"
				class="btns">
				{{ this.res.list[0].btns[8].name }}
			</div>
			<div @click="open()" v-if="this.res.list[0].btns && this.res.list[0].btns[9]" :style="buttonStyle9"
				class="btns">
				{{ this.res.list[0].btns[9].name }}
			</div>
		</div>
	</div>
</template>
<script>
	import {
		modelNavigateTo
	} from "./tpl";

	export default {
		title: "单行图片模块",
		data() {
			return {
				modelNavigateTo,
			};
		},
		props: ["res"],
		mounted() {
			console.log('单图', this.res.list[0]);
		},
		computed: {
			bgStyle() {
				return {
					height: this.res.list[0].bg_height + "px",
					width: this.res.list[0].bg_width + "px",
					marginTop: this.res.list[0].marginTop + "px",
					overflow: "hidden",
					position: "relative",
				};
			},
			buttonStyle0() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[0].bgUrl + ")",
					borderColor: this.res.list[0].btns[0].border_color,
					borderRadius: this.res.list[0].btns[0].border_radius + "px",
					color: this.res.list[0].btns[0].color,
					fontSize: this.res.list[0].btns[0].font_size + "px",
					top: this.res.list[0].btns[0].top + "px",
					left: this.res.list[0].btns[0].left + "px",
					width: this.res.list[0].btns[0].bg_width + "px",
					height: this.res.list[0].btns[0].bg_height + "px",
				};
			},
			buttonStyle1() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[1].bgUrl + ")",
					borderRadius: this.res.list[0].btns[1].border_radius + "px",
					color: this.res.list[0].btns[1].color,
					fontSize: this.res.list[0].btns[1].font_size + "px",
					top: this.res.list[0].btns[1].top + "px",
					left: this.res.list[0].btns[1].left + "px",
					width: this.res.list[0].btns[1].bg_width + "px",
					height: this.res.list[0].btns[1].bg_height + "px",
				};
			},
			buttonStyle2() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[2].bgUrl + ")",
					borderRadius: this.res.list[0].btns[2].border_radius + "px",
					color: this.res.list[0].btns[2].color,
					fontSize: this.res.list[0].btns[2].font_size + "px",
					top: this.res.list[0].btns[2].top + "px",
					left: this.res.list[0].btns[2].left + "px",
					width: this.res.list[0].btns[2].bg_width + "px",
					height: this.res.list[0].btns[2].bg_height + "px",
				};
			},
			buttonStyle3() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[3].bgUrl + ")",
					borderRadius: this.res.list[0].btns[3].border_radius + "px",
					color: this.res.list[0].btns[3].color,
					fontSize: this.res.list[0].btns[3].font_size + "px",
					top: this.res.list[0].btns[3].top + "px",
					left: this.res.list[0].btns[3].left + "px",
					width: this.res.list[0].btns[3].bg_width + "px",
					height: this.res.list[0].btns[3].bg_height + "px",
				};
			},
			buttonStyle4() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[4].bgUrl + ")",
					borderRadius: this.res.list[0].btns[4].border_radius + "px",
					color: this.res.list[0].btns[4].color,
					fontSize: this.res.list[0].btns[4].font_size + "px",
					top: this.res.list[0].btns[4].top + "px",
					left: this.res.list[0].btns[4].left + "px",
					width: this.res.list[0].btns[4].bg_width + "px",
					height: this.res.list[0].btns[4].bg_height + "px",
				};
			},
			buttonStyle5() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[5].bgUrl + ")",
					borderRadius: this.res.list[0].btns[5].border_radius + "px",
					color: this.res.list[0].btns[5].color,
					fontSize: this.res.list[0].btns[5].font_size + "px",
					top: this.res.list[0].btns[5].top + "px",
					left: this.res.list[0].btns[5].left + "px",
					width: this.res.list[0].btns[5].bg_width + "px",
					height: this.res.list[0].btns[5].bg_height + "px",
				};
			},
			buttonStyle6() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[6].bgUrl + ")",
					borderRadius: this.res.list[0].btns[6].border_radius + "px",
					color: this.res.list[0].btns[6].color,
					fontSize: this.res.list[0].btns[6].font_size + "px",
					top: this.res.list[0].btns[6].top + "px",
					left: this.res.list[0].btns[6].left + "px",
					width: this.res.list[0].btns[6].bg_width + "px",
					height: this.res.list[0].btns[6].bg_height + "px",
				};
			},
			buttonStyle7() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[7].bgUrl + ")",
					borderRadius: this.res.list[0].btns[7].border_radius + "px",
					color: this.res.list[0].btns[7].color,
					fontSize: this.res.list[0].btns[7].font_size + "px",
					top: this.res.list[0].btns[7].top + "px",
					left: this.res.list[0].btns[7].left + "px",
					width: this.res.list[0].btns[7].bg_width + "px",
					height: this.res.list[0].btns[7].bg_height + "px",
				};
			},
			buttonStyle8() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[8].bgUrl + ")",
					borderRadius: this.res.list[0].btns[8].border_radius + "px",
					color: this.res.list[0].btns[8].color,
					fontSize: this.res.list[0].btns[8].font_size + "px",
					top: this.res.list[0].btns[8].top + "px",
					left: this.res.list[0].btns[8].left + "px",
					width: this.res.list[0].btns[8].bg_width + "px",
					height: this.res.list[0].btns[8].bg_height + "px",
				};
			},
			buttonStyle9() {
				return {
					backgroundImage: "url(" + this.res.list[0].btns[9].bgUrl + ")",
					borderRadius: this.res.list[0].btns[9].border_radius + "px",
					color: this.res.list[0].btns[9].color,
					fontSize: this.res.list[0].btns[9].font_size + "px",
					top: this.res.list[0].btns[9].top + "px",
					left: this.res.list[0].btns[9].left + "px",
					width: this.res.list[0].btns[9].bg_width + "px",
					height: this.res.list[0].btns[9].bg_height + "px",
				};
			},

		},


		methods: {
			open() {
				this.flag = true;
				this.$emit("callback", this.flag);
			},
		},

	};
</script>
<style lang="scss" scoped>
	@import "./tpl.scss";

	.flex-one {
		width: 100%;
		display: block;
		min-height: 110px;
		overflow: hidden;

		>img {
			width: 100%;
			// height: 100%;
			display: block;
		}

		.btns {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			user-select: none;
			background-size: 100%, 100%;
		}
	}
</style>
