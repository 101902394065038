import instance from '@/utils/request.js'

/**
 * 添加页面模版
 */
export function addPageData (params) {
  return instance({
    url: '/portal/page/data',
    method: 'post',
    data: params
  })
}

/**
 * 获取页面信息
 */
export function getPageData (id) {
  return instance({
    url: '/portal/page/data/' + id,
    method: 'get'
  })
}

/**
 * 查询页面列表
 */
export function pageDataList (query) {
  return instance({
    url: '/portal/page/data/page',
    method: 'get',
    params: query
  })
}

/**
 * 修改页面模版装修
 */
export function putPageData (obj) {
  return instance({
    url: '/portal/page/data/',
    method: 'put',
    data: obj
  })
}

/**
 * 删除楼层装修
 */
export function delPageData (id) {
  return instance({
    url: '/portal/page/data/' + id,
    method: 'delete'
  })
}

/**
 * 发布页面
 */
export function releasePageData (obj) {
  return instance({
    url: '/portal/page/data/release',
    method: 'put',
    data: obj
  })
}
